<template>
  <Article
    :header-image="analyticsUICover"
    :title="title"
    header-text="ux design"
    section-type-name="ux design"
  >
    <template v-slot:body>
      <LumiDSBody :is-dark="isDark" />
    </template>
  </Article>
</template>

<script>
import BackToTop from "@/components/controls/button/BackToTop.vue";
import SectionHeader from "../../../components/section_header/SectionHeader.vue";
import { written } from "@/core/data/written_data";
import Header from "@/components/info/desktop/Header.vue";
import LumiDSBody from "@/components/info/desktop/LumiDSBody.vue";
import { store } from "@/store/store";
import LumiIcon from "@/assets/icon/lumi_icon.vue";
import Article from "@/components/info/desktop/Article.vue";
import analyticsUICover from "@/assets/images/design_system/analytics_ui/sd_cover.webp";

export default {
  store: store,
  components: {
    Article,
    LumiIcon,
    BackToTop,
    Header,
    SectionHeader,
    LumiDSBody,
  },
  computed: {
    title: () => written.uxProjects.luminosoDesignSystem.name,
    introTitle: () =>
      written.uxProjects.luminosoDesignSystem.details.description.title,
    introBody: () =>
      written.uxProjects.luminosoDesignSystem.details.description.description,
    section1Title: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].title,
    section1Body: () =>
      written.uxProjects.luminosoDesignSystem.details.sections[0].intro,

    analyticsUICover: () => analyticsUICover,
    isDark: function () {
      return this.$store.getters.isDark;
    },
  },
};
</script>

<style scoped>
a:link,
a:focus,
a:visited {
  color: #fa713f;
}

.outer-container {
  width: 100vw;
  /* margin: 5rem 0 11% 0; */
  display: grid;
  block-size: 100%;
}

.inner-container {
  display: grid;
  block-size: fit-content;
  grid-template-rows: 20.9rem 1fr;
  grid-template-columns: minmax(11rem, 16vw) 1fr;
  padding-block-end: 10rem;
}

@media screen and (max-width: 830px) {
  .inner-container {
    grid-template-columns: 1fr;
  }
}

.header-image {
  z-index: 0;
  /* left: 19rem; */
  block-size: 100%;
  object-fit: cover;
}

@media screen and (max-width: 830px) {
  .header-image {
    inline-size: 100%;
  }
}

img {
  inline-size: 100%;
}

.header {
  inline-size: 100%;
  display: grid;
  grid-template-columns: minmax(11rem, 20vw) 1fr;
  grid-template-rows: 24vw;
}

.content-container {
  top: 3rem;
  padding-inline-end: 3rem;
  display: grid;
  gap: 9rem;
  grid-template-columns: 1fr;
  grid-column: 2 / span 2;
  inline-size: 100%;
  box-sizing: border-box;
  max-inline-size: 75ch;
}

@media screen and (max-width: 830px) {
  .content-container {
    top: 26rem;
    grid-column: 1 / span 3;
    top: 0;
    inline-size: 100%;
    padding-inline: 4rem;
    padding-block-start: 2rem;
    gap: 2rem;
  }
}

.container {
  width: 100vw;
  display: grid;
  grid-template-rows: auto;
  place-items: center;
}
</style>
